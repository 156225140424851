<script setup lang="ts">
import { z } from "zod";

type Schema = z.output<typeof schema>;

const supabase = useSupabaseClient();
const user = useSupabaseUser();
const state = reactive({
  email: "",
  password: "",
});
const schema = z.object({
  email: z.string().email("Email invalide"),
});

const userLogin = async () => {
  try {
    const { error } = await supabase.auth.signInWithPassword({
      email: state.email,
      password: state.password,
    });
    if (error) throw error;
    
  } catch (error) {
    useToasted({
      type: "error",
      description: "Email et/ou mot de passe incorrect(s)",
      icon: "i-octicon-unlink-24",
    });
  }
};

watchEffect(() => {
  if (user.value) {
    return navigateTo("/");
  }
});
</script>

<template>
  <main class="container">
    <section
      class="flex flex-wrap items-center content-center justify-center h-screen"
    >
      <div>
        <img src="/logo.svg" class="sweepLogo" alt="Sweep Logo" />
      </div>

      <UForm
        :schema="schema"
        :state="state"
        method="post"
        enctype="application/x-www-form-urlencoded"
        class="w-full flex flex-col pt-8 gap-y-4"
        @submit="userLogin"
      >
        <UFormGroup name="email">
          <template #label
            ><p class="text-xs font-bold py-2">Email</p></template
          >
          <UInput
            color="gray"
            type="email"
            variant="outline"
            v-model="state.email"
          />
        </UFormGroup>

        <UFormGroup name="password">
          <template #label
            ><p class="text-xs font-bold py-2">Mot de passe</p></template
          >
          <UInput
            color="gray"
            variant="outline"
            v-model="state.password"
            type="password"
          />
        </UFormGroup>
        <ULink
          to="/reset-password"
          active-class="text-primary"
          inactive-class="text-gray-500 text-xs font-bold flex justify-end dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
        >
          Mot de passe oublié ?
        </ULink>
        <UButton
          label="Se connecter"
          class="mt-4"
          color="green"
          type="submit"
          block
        />
      </UForm>
      <UNotifications />
    </section>
  </main>
</template>

<style scoped>
.sweepLogo {
  width: 182px;
  height: 26px;
}
</style>
